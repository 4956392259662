import React, { Component } from "react"
// import $ from 'jquery';
// import { Router } from "@reach/router"
import {graphql} from 'gatsby'
import Layout from 'components/layout/basic';
import Provider from 'components/providers/single';
import parse from 'html-react-parser';
import PatientEducationController from 'controllers/blog/patienteducation';
import SEO from 'shared/seo';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

export default class PhysiciansProvidersGet extends Component {
	constructor(props) {
		super(props);
		this.provider = (this.props.data.allPhysiciansProvider && this.props.data.allPhysiciansProvider.edges.length ? this.props.data.allPhysiciansProvider.edges[0].node : null);
		this.hospitals = (this.props.data.allLocationsHospital && this.props.data.allLocationsHospital.edges.length ? this.props.data.allLocationsHospital.edges : null);
		this.specialties = (this.props.data.allPhysiciansSpecialty && this.props.data.allPhysiciansSpecialty.edges.length ? this.props.data.allPhysiciansSpecialty.edges : null);
		this.locations = (this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges : null);
		this.schools = (this.props.data.allPhysiciansSchool && this.props.data.allPhysiciansSchool.edges.length ? this.props.data.allPhysiciansSchool.edges : null);
		let initialData = this.processData((this.props.data.allPhysiciansProvider && this.props.data.allPhysiciansProvider.edges.length ? this.props.data.allPhysiciansProvider.edges[0].node : null));	
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}

		this.liveRefresh = this.liveRefresh.bind(this);
		this.processData = this.processData.bind(this);
	}

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/school/all`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.schools = data;
				}
			})
			.catch(err => console.log);
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/provider/get/?_join=Location,Certification,Association,Category,Hospital&uri=${this.provider.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let physician = this.processData(data[0]);
					this.setState({
						status: STATUS.LOADED,
						data: physician,
					})
				}
				console.log('Live Refresh Complete');
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			firstName: data.firstName,
			lastName: data.lastName,
			maidenName: data.maidenName,
			middleName: data.middleName,
			nickName: data.nickName,
			title: data.abbreviations ? data.abbreviations.join(', ') : null,
			category: data.Category[0].object.name,
			specialty: [],
			specialtyIDs: [],
			phone: data.directPhone,
			fax: data.fax,
			cta: 'Visit Profile',
			photo: data.photo,
			link: data.uri,
			bio: data.biography,
			patientsAccepted: data.patientsAccepted,
			patientsAcceptedDescription: data.patientsAcceptedDescription,
			gender: data.gender,
			awards: null,
			education: null,
			fellowship: null,
			internship: null,
			certifications: null,
			associations: null,
			locations: null,
			residency: null,
			hospital: null,
			patientLiaison: data.patientLiaison,
			awards: null,
			awardsImages: null,
			specialtyExtra: data.specialtyExtra,
			selfSchedulingLink: data.selfSchedulingLink,
			// awards: [
			// 	{
			// 		year: '2020',
			// 		name: 'Award Name',
			// 	},
			// 	{
			// 		year: '2019',
			// 		name: 'Award Name',
			// 	},
			// 	{
			// 		year: '2018',
			// 		name: 'Award Name',
			// 	},
			// 	{
			// 		year: '2017',
			// 		name: 'Award Name',
			// 	},
			// 	{
			// 		year: '2016',
			// 		name: 'Award Name',
			// 	}
			// ],
		};
		if (data.award && data.award.length) {
			let awards = [];
			let awardsImages = [];
			data.award.forEach(award => {
				let awardObj = {};
				awardObj.year = award.year;
				awardObj.name = award.name;
				awards.push(awardObj);
				if (award.photo) {
					awardsImages.push(award.photo);
				}
			});
			if (awards.length) {
				newDatum.awards = awards;
			} else {
				newDatum.awards = null;
			}
			if (awardsImages.length) {
				newDatum.awardsImages = awardsImages;
			} else {
				newDatum.awardsImages = null;
			}
		}
		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			data.Specialty.forEach(specialty => {
				if (specialty.object.id) {
					this.specialties.forEach(item => {
						if (item.node.id === specialty.object.id) {
							specialties.push(item.node.name);
							newDatum.specialtyIDs.push(specialty.object.id);
						}
					});
				}
			});
			if (specialties.length) {
				specialties.sort();
				newDatum.specialty = specialties;
			} else {
				newDatum.specialty = null;
			}
		}
		if (data.MedicalSchool && data.MedicalSchool.length) {
			let medschool = [];
			data.MedicalSchool.forEach(school => {
				if (school.object.id) {
					this.schools.forEach(item => {
						if ("node" in item) {
							if (item.node.id === school.object.id) {
								medschool.push(item.node.name);
							}
						} else {
							if (item.id === school.object.id) {
								medschool.push(item.name);
							}
						}
					});
				}
			});
			if (medschool.length) {
				newDatum.education = medschool;
			} else {
				newDatum.education = null;
			}
		}
		if (data.Fellowship && data.Fellowship.length) {
			let fellowship = [];
			data.Fellowship.forEach(place => {
				if (place.object.id) {
					this.schools.forEach(item => {
						if ("node" in item) {
							if (item.node.id === place.object.id) {
								fellowship.push(item.node.name);
							}
						} else {
							if (item.id === place.object.id) {
								fellowship.push(item.name);
							}
						}
					});
				}
			});
			if (fellowship.length) {
				newDatum.fellowship = fellowship;
			} else {
				newDatum.fellowship = null;
			}
		}
		if (data.Internship && data.Internship.length) {
			let intern = [];
			data.Internship.forEach(datum => {
				if (datum.object.id) {
					this.schools.forEach(item => {
						if ("node" in item) {
							if (item.node.id === datum.object.id) {
								intern.push(item.node.name);
							}
						} else {
							if (item.id === datum.object.id) {
								intern.push(item.name);
							}
						}
					});
				}
			});
			if (intern.length) {
				newDatum.internship = intern;
			} else {
				newDatum.internship = null;
			}
		}
		if (data.Certification && data.Certification.length) {
			let certifications = [];
			data.Certification.forEach(certification => {
				certifications.push(certification.object.name);
			});
			if (certifications.length) {
				newDatum.certifications = certifications;
			} else {
				newDatum.certifications = null;
			}
		}
		if (data.Association && data.Association.length) {
			let associations = [];
			data.Association.forEach(association => {
				associations.push(association.object.name);
			});
			if (associations.length) {
				newDatum.associations = associations;
			} else {
				newDatum.associations = null;
			}
		}
		if (data.Residency && data.Residency.length) {
			let residencies = [];
			data.Residency.forEach(residency => {
				if (residency.object.id) {
					this.schools.forEach(item => {
						if ("node" in item) {
							if (item.node.id === residency.object.id) {
								residency.object.name = item.node.name;
							}
						} else {
							if (item.id === residency.object.id) {
								residency.object.name = item.name;
							}
						}
					});
				}
				if (residency.properties.hospitalID) {
					this.hospitals.forEach(hospital => {
						if (hospital.node.id === residency.properties.hospitalID) {
							residency.object.hospital = hospital.node;
						}
					});
				}
				residencies.push(residency.object);
			});
			if (residencies.length) {
				newDatum.residency = residencies;
			} else {
				newDatum.residency = null;
			}
		}
		if (data.Hospital && data.Hospital.length) {
			let hospitals = [];
			data.Hospital.forEach(residency => {
				hospitals.push(residency.object);
			});
			if (hospitals.length) {
				newDatum.hospital = hospitals;
			} else {
				newDatum.hospital = null;
			}
		}
		if (data.Location && data.Location.length) {
			let locations = [];
			data.Location.forEach(location => {
				let locObject = {
					id: location.object.id,
					name: location.object.name,
					address: location.object.address1,
					city: location.object.city,
					state: location.object.state,
					zip: location.object.zip,
					phone: location.object.phone,
					primary: location.properties.primary,
					fax: null,
					hours: {},
					latitude: location.object.position[0],
					longitude: location.object.position[1],
					hoursNotes: null,
					sort: 0,
				}
				if (location.properties.hoursMon) { locObject.hours.Mo = location.properties.hoursMon };
				if (location.properties.hoursTue) { locObject.hours.Tu = location.properties.hoursTue };
				if (location.properties.hoursWed) { locObject.hours.We = location.properties.hoursWed };
				if (location.properties.hoursThu) { locObject.hours.Th = location.properties.hoursThu };
				if (location.properties.hoursFri) { locObject.hours.Fr = location.properties.hoursFri };
				if (location.properties.hoursSat) { locObject.hours.Sa = location.properties.hoursSat };
				if (location.properties.hoursSun) { locObject.hours.Su = location.properties.hoursSun };
				if (location.properties.hoursNotes) { locObject.hoursNotes = location.properties.hoursNotes };
				
				let physicianFloor = false;
				if (location.properties.floor) { 
					locObject.floor = location.properties.floor
					physicianFloor = true;
				};
				if (location.object.id && !physicianFloor) {
					if (this.locations.length) {
						this.locations.forEach(item => {
							if (item.node.id === location.object.id) {
								locObject.sort = item.node.sort;
								if (item.node.Specialty.length) {
									item.node.Specialty.forEach(specialtyItem => {
										newDatum.specialtyIDs.forEach(id => {
											if (specialtyItem.object.id === id) {
												if (specialtyItem.properties) {
													if (specialtyItem.properties.floor && specialtyItem.properties.floor !== '') {
														locObject.floor = specialtyItem.properties.floor;
													}
												}
											}
										})
									})
								}
							}
						});
					}
				}
				locations.push(locObject);
			});
			if (locations.length) {
				locations.forEach(location => {
					if (location.primary) {
						location.sort = -1;
					}
				});
				locations.sort((a, b) => {
					let fa = a.sort,
						fb = b.sort;

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
				newDatum.locations = locations;
			} else {
				newDatum.locations = null;
			}
		}
		return newDatum;
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<>
				<Layout>
					<Provider data={this.state.data} />
					<hr />
					<PatientEducationController />
					<SEO title={`${this.state.data.firstName} ${this.state.data.lastName}${this.state.data.title ? `, ${this.state.data.title}` : ''} | ${this.props.data.site.siteMetadata.title}`} image={`${this.props.data.site.siteMetadata.imgCDN}${(this.state.data.photo) ? this.state.data.photo : '/userfiles/dr-blank.jpg'}`}/>
				</Layout>
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allPhysiciansProvider(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        firstName
	        lastName
	        maidenName
	        middleName
	        nickName
	        namePrefix
	        nameSuffix
	        abbreviations
	        photo
	        biography
	        gender
	        npi
	        directPhone
	        patientsAccepted
	        patientsAcceptedDescription
	        patientLiaison
	        award {
	          name
	          photo
	          year
	        }
	        Location {
	          object {
	          	id
	            name
	            address1
	            address2
	            city
	            state
	            zip
	            uri
	            permalink
	            phone
	            position
	          }
	          properties {
	          	hoursMon
	            hoursTue
	            hoursWed
	            hoursThu
	            hoursFri
	            hoursSat
	            hoursSun
	            hoursNotes
	            primary
	            floor
	          }
	        }
	        Certification {
	          object {
	          	name
	          }
	        }
	        Association {
	          object {
	          	name
	          }
	        }
	        Category {
	          object {
	          	name
	          }
	        }
	        Specialty {
	          object {
	            id
	          }
	        }
	        Internship {
	          object {
	            id
	          }
	        }
	        Fellowship {
	          object {
	            id
	          }
	        }
	        MedicalSchool {
	          object {
	            id
	          }
	        }
	        Residency {
	          object {
	            id
	          }
	          properties {
                hospitalID
              }
	        }
	      }
	    }
	  }
	  allLocationsHospital(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	        address1
	        city
	        state
	        zip
	      }
	    }
	  }
	  allLocationsLocation(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        sort
	        Specialty {
	          object {
	            id
	          }
	          properties {
	            floor
	          }
	        }
	      }
	    }
	  }
	  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  allPhysiciansSchool(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	      }
	    }
	  }
	  site {
	    siteMetadata {
	      imgCDN
	      title
	    }
	  }
	}
`
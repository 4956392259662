import React, {Component} from 'react'
import Background from 'shared/background'
import Icon from 'components/icon'
import Link from 'shared/link'
import LocationsMap from 'components/locations/map'
import parse from 'html-react-parser';
import style from './single.module.scss';

class Provider extends Component {
	constructor(props) {
		super(props);
		this.phoneConvert = this.phoneConvert.bind(this);
		this.state = {
			key: 'initial',
		};
	}

	phoneConvert(input) {
		var inputlength = input.length;
		input = input.toLowerCase();
		var phonenumber = "";
		for (let i = 0; i < inputlength; i++) {
			var character = input.charAt(i);

			switch(character) {
				case '0': phonenumber+="0";break;
				case '1': phonenumber+="1";break;
				case '2': phonenumber+="2";break;
				case '3': phonenumber+="3";break;
				case '4': phonenumber+="4";break;
				case '5': phonenumber+="5";break;
				case '6': phonenumber+="6";break;
				case '7': phonenumber+="7";break;
				case '8': phonenumber+="8";break;
				case '9': phonenumber+="9";break;
				case '-': phonenumber+="-";break;
				case  'a': case 'b': case 'c': phonenumber+="2";break;
				case  'd': case 'e': case 'f': phonenumber+="3";break;
				case  'g': case 'h': case 'i': phonenumber+="4";break;
				case  'j': case 'k': case 'l': phonenumber+="5";break;
				case  'm': case 'n': case 'o': phonenumber+="6";break;
				case  'p': case 'q': case 'r': case 's': phonenumber+="7";break;
				case  't': case 'u': case 'v': phonenumber+="8";break;
				case  'w': case 'x': case 'y': case 'z': phonenumber+="9";break;
			}
		}
		phonenumber = phonenumber.replace(/-/g, '');
		return phonenumber;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				key: 'updated',
			})
			console.log('Force Update Complete');
		}
	}

	render() {
		var provider = this.props.data;
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className={[style.sideWrapper, "cell medium-4 small-order-2 medium-order-1"].join(' ')}>
						<div className={[style.imageWrapper, 'show-for-medium', 'grid-x'].join(' ')}>
							<div className={[style.photoFrame, 'show-for-medium'].join(' ')}>
								<div key={this.state.key} className={style.image}>
									<Background lowQuality={false} crop="faces,center" ar="1:1.2" width={800} className={style.photo} image={(this.props.data.photo) ? this.props.data.photo : '/userfiles/dr-blank.jpg'} />
								</div>
							</div>
							<div className={[style.phonesContainer].join(' ')}>
								<p className={(provider.phone) ? style.clinicPhone : style.noMarg}>{(provider.phone) ? parse(`Phone: <span><a href="tel:+1${this.phoneConvert(provider.phone)}">${provider.phone}</a></span>`) : ''}</p>
								<p className={(provider.fax) ? style.clinicPhone : style.noMarg}>{(provider.fax) ? parse(`Fax: <span>${provider.fax}</span>`) : ''}</p>
								<Link className={[style.cta, "button", `${(provider.selfSchedulingLink) ? '' : 'hide'}`].join(' ')} to="https://phreesia.me/HeritageMedicalAssociates">Schedule Appointment</Link>
							</div>
						</div>
						{provider.locations && <div className={[style.locationsWrapper, 'grid-x'].join(' ')}>
							<div className={[style.map, "cell small-12"].join(' ')}>
								<div className={style.mapHolder}>
									<LocationsMap height='300px' data={provider.locations} />
								</div>
							</div>
							<div className={style.locations}>
							{provider.locations.map((location, index) => (
								<div key={index} className={[style.locationInfo, "cell small-12"].join(' ')}>
									<div className="grid-x">
										<div className={[style.address, 'cell small-12'].join(' ')}>
											<p className={style.locName}>{location.name}</p>
											<p className={style.locAddress}>{location.address}<br />{location.floor ? parse(`${location.floor}<br />`) : ''}{location.city}, {location.state} {location.zip}</p>
										</div>
										{/*<div className={[style.commNumbers, 'cell small-12'].join(' ')}>
											<div className={style.number}><Icon className={style.icon} icon="phone" /> {location.phone}</div>
											<div className={style.number}><Icon className={style.icon} icon="fax" /> {location.fax}</div>
										</div>*/}
										{Object.keys(location.hours).length > 0 && <div className={[style.hours, 'cell small-12'].join(' ')}>
											<p className={style.hoursTitle}>Hours:</p>
											<div className={style.hoursList}>
												{Object.keys(location.hours).map((key, index) => (
													<p key={index} className={style.dayHours}>{key}: {location.hours[key]}</p>
												))}
											</div>
											<p className={location.hoursNotes ? style.hoursNotes : 'hide'}>{location.hoursNotes}</p>
										</div>}
									</div>
									<div className={[style.ctaRow, "cell small-12"].join(' ')}>
										<Link className={[style.cta, "button"].join(' ')} to="https://www.google.com/maps/"><Icon className={style.directionsIcon} icon="directions" /> Get Directions</Link>
									</div>
								</div>
							))}
							</div>
						</div>}
					</div>
					<div className={[style.mainWrapper, "cell medium-8 small-order-1 medium-order-2"].join(' ')}>
						<div className={[provider.patientLiaison ? style.newPatientRow : 'hide', 'grid-x'].join(' ')}>
							<div className="cell auto">
								<p className={style.intro}><span>New Patient?</span>Call to request an appointment.</p>
							</div>
							<div className="cell shrink"><Icon icon="phone" className={style.icon} /><Link to="tel:+16292553584" external={true}>629-255-3584</Link></div>
						</div>
						<div className={[style.inner, "grid-x"].join(' ')}>
							<div className={[style.pageTitle, "cell small-12"].join(' ')}>
								<h1>{provider.firstName}{provider.middleName ? ` ${provider.middleName} ` : ' '}{provider.nickName ? ` "${provider.nickName}" ` : ' '}{provider.lastName}{provider.title ? `, ${provider.title}` : ''}</h1>
								<div className={style.specialty}>{provider.category ? `${provider.category} - ` : ''}{(provider.specialty) ? provider.specialty.join(', ') : ''}{provider.specialtyExtra ? parse(`<br />${provider.specialtyExtra}`) : ''}</div>
							</div>
							<div className={[style.mobilePhoto, 'cell small-12 hide-for-medium']}>
								<div className={[style.photoFrame].join(' ')}>
									<div key={this.state.key} className={style.image}>
										<Background lowQuality={false} crop="faces,center" ar="1:1.2" width={800} className={style.photo} image={(this.props.data.photo) ? this.props.data.photo : '/userfiles/dr-blank.jpg'} />
									</div>
								</div>
								<div className={[style.phonesContainer].join(' ')}>
									<p className={style.clinicPhone}>{(provider.phone) ? parse(`Phone: <span><a href="tel:+1${this.phoneConvert(provider.phone)}">${provider.phone}</a></span>`) : ''}</p>
									<p className={style.clinicPhone}>{(provider.fax) ? parse(`Fax: <span>${provider.fax}</span>`) : ''}</p>
								</div>
							</div>
							<div className={[(provider.bio) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={style.bioTitle}>{(provider.bio) ? 'About' : ''}</div>
								<div>{(provider.bio) ? parse(provider.bio) : ''}</div>
							</div>
							<div className={[(provider.patientsAcceptedDescription) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={style.bioTitle}>{(provider.patientsAcceptedDescription) ? 'Ages Seen' : ''}</div>
								<div>{(provider.patientsAcceptedDescription) ? parse(provider.patientsAcceptedDescription) : ''}</div>
							</div>
							<div className={[(provider.education) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.education) ? style.bioTitle : ''}>{provider.education ? 'Education' : ''}</div>
								{provider.education && provider.education.map((item, index) => (
									<div key={index}>{(item) ? parse(item) : ''}</div>
								))}
							</div>
							<div className={[(provider.residency) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.residency) ? style.bioTitle : ''}>{provider.residency ? 'Residency' : ''}</div>
								{provider.residency && provider.residency.map((item, index) => (
									<div key={index}>{`${(item.name) ? parse(item.name) : ''}${item.hospital ? ' at '+item.hospital.name+' in '+item.hospital.city+', '+item.hospital.state : ''}`}</div>
								))}
							</div>
							<div className={[(provider.fellowship) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.fellowship) ? style.bioTitle : ''}>{provider.fellowship ? 'Fellowship' : ''}</div>
								{provider.fellowship && provider.fellowship.map((item, index) => (
									<div key={index}>{(item) ? parse(item) : ''}</div>
								))}
							</div>
							<div className={[(provider.certifications) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.certifications) ? style.bioTitle : ''}>{provider.certifications ? 'Certifications' : ''}</div>
								{provider.certifications && provider.certifications.map((certification, index) => (
									<div key={index}>{(certification) ? parse(certification) : ''}</div>
								))}
							</div>
							<div className={[(provider.associations) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.associations) ? style.bioTitle : ''}>{(provider.associations) ? 'Associations' : ''}</div>
								{provider.associations && provider.associations.map((association, index) => (
									<div key={index}>{(association) ? parse(association) : ''}</div>
								))}
							</div>
							<div className={[(provider.awards) ? style.bioSection : '', 'cell small-12'].join(' ')}>
								<div className={(provider.awards) ? style.bioTitle : ''}>{(provider.awards) ? 'Awards' : ''}</div>
								{provider.awardsImages && 
									<div className={[style.awardImages, "grid-x small-up-2 medium-up-6 grid-margin-x"].join(' ')}>
									{provider.awardsImages.map((image, index) => (
									<div className={[style.awardImage, 'cell'].join(' ')} key={index}>
										<div className={[style.photoFrame].join(' ')}>
											<div className={style.image}>
												<Background width={800} className={style.photo} image={image} />
											</div>
										</div>
									</div>
								))}
									</div>}
								{provider.awards && provider.awards.map((award, index) => (
									<div className={(award.year || award.name) ? style.award : ''} key={index}>{(award.year) ? `${parse(award.year)} | ` : ''}{parse(award.name)}</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Provider;